




















































import {Component, Vue} from 'vue-property-decorator';
import DocumentService from "@/services/DocumentService";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import {namespace} from "vuex-class";
import {processError} from "@/utils/ComponentUtils";
import CompanyDTO from "@/dto/company/CompanyDTO";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import DocumentsReportsDTO from "@/dto/archive/DocumentsReportsDTO";
import DocumentUtils from "@/utils/DocumentUtils";

const AppModule = namespace("App");

@Component<DocumentsReports>({
  components: { PaginationComponent, SimpleCompanySearch },
  computed: {
    DocumentUtils() {
      return DocumentUtils;
    }
  }
})
export default class DocumentsReports extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private pageNumber = 1
  private totalPages = 1
  private companyId: number | null = null;
  private reports: Array<DocumentsReportsDTO> | [] = [];

  mounted(): void {
    this.getDocumentsReportsPage();
  }

  getDocumentsReportsPage(): void {
    this.startLoading()
    DocumentService.getReports(this.companyId, this.pageNumber, 20).then(
        response => {
          this.reports = response.data.data;
          this.totalPages = response.data.countOfPages;
          this.stopLoading();
        },
        error => {
          this.stopLoading();
          processError(this, error);
        }
    )
  }

  private changePage(page: number): void {
    this.pageNumber = page;
    this.getDocumentsReportsPage();
  }

  private selectCompany(company: CompanyDTO) {
    this.companyId = company.id;
    this.getDocumentsReportsPage();
  }

}
